import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Divider, Grid, Slide, Snackbar } from "@mui/material";
import nhlogo from "assets/images/avs-logo.png";
import pattern from "assets/images/shapes/pattern-lines.svg";
// Authentication layout components
import BaseLayout from "components/LayoutContainers/BaseLayout";
// NextJS Material Dashboard 2 PRO components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiLoadingButton from "components/SuiLoadingButton";
// Soft UI Dashboard React components
import SuiSnackbarAlert from "components/SuiSnackbarAlert";
import firebase from "firebase/app";
import "firebase/auth";
import { AuthAction, withAuthUser, withAuthUserTokenSSR } from "next-firebase-auth";
import { useState } from "react";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import getAbsoluteURL from "utils/getAbsoluteURL";

function Login() {
  const [email, setEmail] = useState("");
  const handleSetEmail = (e) => setEmail(e.target.value);
  const [password, setPassword] = useState("");
  const handleSetPassword = (e) => setPassword(e.target.value);
  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertColor, setAlertColor] = useState("error");
  const [openAlert, setOpenAlert] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);

  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const onFormSubmit = e => {
    e.preventDefault();
    handleLogin();
  }

  const handleLogin = () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((u) => {
        setAlertText("Login Successful. Redirecting...");
        setAlertColor("success");
        handleOpenAlert();
      })
      .catch((error) => {
        setAlertText(error.message);
        setAlertColor("error");
        handleOpenAlert();
        setLoading(false);
      });
  };

  const onForgotSubmit = e => {
    e.preventDefault();
    handleForgot();
  }

  const handleForgot = async () => {
    setLoading(true);
    const endpoint = getAbsoluteURL(`/api/tickets/anonymousCreate`);
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: email,
      }),
    });
    const data = await response.json();
    if (data.error) {
      setAlertText(data.error);
      setAlertColor("error");
      handleOpenAlert();
      setLoading(false);
    } else {
      setAlertText("Email sent. A response will be made as soon as possible.");
      setAlertColor("success");
      handleOpenAlert();
      setLoading(false);
      setEmail("");
      setForgotOpen(false);
    }
  };

  const passwordRequirements = [
    "Enter your e-mail address",
    "Your request will be forwarded to human resources",
    "Human resources will change your password",
    "You will be contacted via e-mail as soon as possible",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SuiBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1.25}>
        <SuiTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </SuiTypography>
      </SuiBox>
    );
  });

  return (
    <BaseLayout
      title={forgotOpen ? "Forgot password" : "Login"}
      description={
        forgotOpen ? "Enter your email to reset your password" : "Enter your email and password to sign in"
      }
      color={"info"}
      illustration={{
        image: nhlogo,
        pattern: pattern,
      }}
      login
      stickyNavbar
    >
      {forgotOpen ? (
        <SuiBox component="form" role="form" onSubmit={onForgotSubmit}>
          <SuiBox mb={2}>
            <SuiInput
              type="email"
              size="large"
              placeholder="Email"
              value={email}
              onChange={handleSetEmail}
              required
            />
            <SuiBox pt={2} lineHeight={1}>
              <SuiTypography variant="h6" fontWeight="medium">
                For your information
              </SuiTypography>
              <SuiTypography variant="button" fontWeight="regular" color="text">
                For a new password, please follow this guide:
              </SuiTypography>
            </SuiBox>
            <SuiBox p={2}>
              <SuiBox component="ul" m={0} pl={2.5} mb={{ xs: 8, sm: 0 }}>
                {renderPasswordRequirements}
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <SuiBox mt={5} mb={1}>
            <SuiButton
              variant="gradient"
              loading={loading}
              loadingIndicator="Loading..."
              type="submit"
              color="dark"
              size="large"
              fullWidth
            >
              send
            </SuiButton>
          </SuiBox>
          <SuiBox mt={1} mb={1}>
            <SuiButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              onClick={() => setForgotOpen(false)}
            >
              back
            </SuiButton>
          </SuiBox>
        </SuiBox>
      ) : (
        <SuiBox component="form" role="form" onSubmit={onFormSubmit}>
          <SuiBox mb={2}>
            <SuiInput
              type="email"
              placeholder="Email"
              size="large"
              value={email}
              onChange={handleSetEmail}
              required
            />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiInput
              type="password"
              placeholder="Password"
              size="large"
              value={password}
              onChange={handleSetPassword}
              required
            />
          </SuiBox>
          <SuiBox mt={3} mb={1}>
            <SuiLoadingButton
              type="submit"
              variant="gradient"
              color="info"
              size="large"
              disable
              loading={loading}
              loadingIndicator="Loading..."
              fullWidth
            >
              sign in
            </SuiLoadingButton>
          </SuiBox>
          <SuiBox position="relative" py={0.25}>
            <Divider />
            <SuiBox
              bgColor="#F8F9FA"
              position="absolute"
              top="50%"
              left="50%"
              px={1.5}
              lineHeight={1}
              sx={{ transform: "translate(-50%, -60%)" }}
            >
              <SuiTypography mx={1} variant="button" fontWeight="medium" color="secondary">
                or
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiButton
            variant="text"
            color="text"
            onClick={() => setForgotOpen(true)}
            fullWidth
          >
            <SuiTypography variant="button" fontWeight="regular" color="text">
              Forgot password ?
            </SuiTypography>
          </SuiButton>
        </SuiBox>
      )}
      {/* <SuiBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SuiTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SuiTypography>
        </SuiBox> */}

      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        TransitionComponent={Slide}
        onClose={handleCloseAlert}
      >
        <SuiSnackbarAlert
          onClose={handleCloseAlert}
          icon={<ErrorOutlineIcon fontSize="inherit" color="white" />}
          color={alertColor}
        >
          {alertText}
        </SuiSnackbarAlert>
      </Snackbar>
    </BaseLayout>
  );
}

export const getServerSideProps = withAuthUserTokenSSR({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})()

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(Login)
